import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Card } from "react-bootstrap";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Quote from "../components/quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import CommunicateIcon from "../icons/communicate.svg";
import EducationIcon from "../icons/education-3.svg";
import PaySetupIcon from "../icons/pay-setup.svg";
import CalendarIcon from "../icons/calendar-planning.svg"
import ReportsIcon from "../icons/improved-reports.svg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="What To Expect"
        description="Before you sign on, ask our sales team what to expect when your project kicks off and throughout the life of our partnership. You won&#039;t be dissapointed!"
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">
              WHAT YOU CAN EXPECT FROM WORKING WITH <br /> 729 SOLUTIONS
            </h1>
            <p className="text-white">
              729 Solutions has been implementing best of breed software for 15
              years so we know how to run a well-managed project. We will give
              you real-time visibility into everything we do. We can scope your
              project within 3 days, provide an estimate within a week, and
              start work as soon as the contract is complete.
            </p>
            <CTA
              href="/contact-us/"
              text="Tell us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <Card className="card custom-cards card-borderless text-left mb-5 py-4">
                <CommunicateIcon 
                  className="m-auto"
                  width={144}
                  height={144}
                  alt="Communication"
                />
                <Card.Body>
                  <Card.Title as="h3">Communication</Card.Title>
                  <Card.Text>
                    <p>
                      You can expect to have at least a weekly call with a
                      Project Manager to help you run the process smoothly.
                    </p>
                    <p>
                      We use Slack, Teams, Asana, or any other tool of your
                      choice for regular communication and team sharing.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card className="card custom-cards card-borderless text-left mb-5 py-4">
                <EducationIcon
                  className="m-auto"
                  width={144}
                  height={144}
                  alt="No Dependency"
                />
                <Card.Body>
                  <Card.Title as="h3">No Dependency</Card.Title>
                  <Card.Text>
                    729’s philosophy is always to train our customers on how to
                    do everything themselves. We want to set you up for success
                    which means your team being able to manage your tools and
                    software proficiently. We will train you in any custom
                    workflows we create so you don’t need to call us to make
                    small changes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-4">
              <Card className="card custom-cards card-borderless text-left mb-5 py-4">
                <PaySetupIcon
                  className="m-auto"
                  width={144}
                  height={144}
                  alt="Control of Time and Budget"
                />
                <Card.Body>
                  <Card.Title as="h3">Control of Time and Budget</Card.Title>
                  <Card.Text>
                    As a customer, you will have total control of budget and
                    priorities. Every week you will be able to set goals and
                    tasks and change them at any time. You can set caps on hours
                    so there is no wasted effort. We only work by the hour and
                    charge only to the 15-minute increment so you know you are
                    getting exactly what you paid for.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Card className="card custom-cards card-borderless text-left mb-5 py-4">
                <CalendarIcon
                  className="m-auto"
                  width={144}
                  height={144}
                  alt="Always there for you"
                />
                <Card.Body>
                  <Card.Title as="h3">Always there for you</Card.Title>
                  <Card.Text>
                    We believe we should always be a partner – not just a
                    vendor. You will have access to our full team of experts and
                    resources at any time you need for recommendations and
                    advice on best practices.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-12 col-lg-6">
              <Card className="card custom-cards card-borderless text-left mb-5 py-4">
                <ReportsIcon
                  className="m-auto"
                  width={144}
                  height={144}
                  alt="Reporting"
                />
                <Card.Body>
                  <Card.Title as="h3">Reporting</Card.Title>
                  <Card.Text>
                    You will get a weekly report with time and effort spent,
                    jobs worked on, and any suggestions for improving speed and
                    efficiency.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dark">
          <div className="container">
            <div className="row py-6">
              <div className="col">
                <Quote
                  dark
                  quote="It's clear 729 values the relationship, and in doing so, earned our trust."
                  name="Sher Amos-Grosser"
                  company="San Mateo 4C"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="row">
          <div className="col">
            <div className="text-center p-5 bg-light">
              <h2>I Need a Quote</h2>
              <p>
                Reach out today. We’ll respond within 24 hours, and can scope
                your project within 3 days.
              </p>
              <CTA
                href="/contact-us/"
                text="Get a Quote"
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
